var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('transition',{attrs:{"name":"fade"}},[(_vm.loaded)?_c('section',{staticClass:"productsSection"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"breadWrp"},[_c('bread-crumbs',{attrs:{"items":_vm.brc}}),_c('a',{staticClass:"back",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t("back"))+" "),_c('span',{staticClass:"decoLine1"})])],1),_c('div',{staticClass:"tileWrp"},[_c('h1',[_vm._v(" "+_vm._s(_vm.category ? _vm.category.h1 : _vm.$t("product.productsTitle"))+" ")])]),_c('div',{staticClass:"sideSectionWrp"},[_c('catalog-page-filter',{attrs:{"d":_vm.categories,"title":_vm.$t('catalogTitle.prod')}})],1),_c('div',{staticClass:"prodGridWrp"},[(_vm.products)?[_c('products-grid',{attrs:{"count":_vm.products.length}},_vm._l((_vm.products),function(item,index){return _c('product-grid-card',{key:'c' + index,attrs:{"d":item}})}),1)]:_vm._e(),_c('div',{staticClass:"lnkWrp"},[(_vm.$route.name == 'products')?_c('router-link',{staticClass:"button2 left",attrs:{"to":{
                                name: 'MainPage',
                                params: {
                                    locale:
                                        _vm.$i18n.locale == 'uk'
                                            ? null
                                            : _vm.$i18n.locale
                                }
                            }}},[_vm._v(_vm._s(_vm.$t("pages.main"))+" "),_c('span',{staticClass:"ic-icarrow-left"})]):(_vm.$route.name == 'productscategory')?_c('router-link',{staticClass:"button2 left",attrs:{"to":{
                                name: 'products',
                                params: {
                                    locale:
                                        _vm.$i18n.locale == 'uk'
                                            ? null
                                            : _vm.$i18n.locale
                                }
                            }}},[_vm._v(_vm._s(_vm.$t("pages.products"))+" "),_c('span',{staticClass:"ic-icarrow-left"})]):_vm._e()],1),_c('div',{staticClass:"pagiWrp"},[_c('pagination',{attrs:{"compdata":_vm.pagiData},on:{"click":_vm.onPagiClick}})],1)],2)])]):_vm._e()]),(_vm.$store.state.doctorAlert)?_c('modal-base',[_c('caution-modal',{on:{"close":_vm.onModalClose,"accept":_vm.onModalAccept}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }