<template>
    <main>
        <transition name="fade">
            <section v-if="loaded" class="productsSection">
                <div class="container">
                    <div class="breadWrp">
                        <bread-crumbs :items="brc"></bread-crumbs>
                        <a href="#" class="back"
                            >{{ $t("back") }} <span class="decoLine1"></span
                        ></a>
                    </div>
                    <div class="tileWrp">
                        <h1>
                            {{
                                category
                                    ? category.h1
                                    : $t("product.productsTitle")
                            }}
                        </h1>
                        <!-- <h1>{{ $t("product.productsTitle") }}</h1> -->
                    </div>
                    <div class="sideSectionWrp">
                        <catalog-page-filter
                            :d="categories"
                            :title="$t('catalogTitle.prod')"
                        ></catalog-page-filter>
                    </div>
                    <div class="prodGridWrp">
                        <template v-if="products">
                            <products-grid :count="products.length">
                                <product-grid-card
                                    v-for="(item, index) in products"
                                    :key="'c' + index"
                                    :d="item"
                                ></product-grid-card>
                            </products-grid>
                        </template>
                        <div class="lnkWrp">
                            <router-link
                                v-if="$route.name == 'products'"
                                :to="{
                                    name: 'MainPage',
                                    params: {
                                        locale:
                                            $i18n.locale == 'uk'
                                                ? null
                                                : $i18n.locale
                                    }
                                }"
                                class="button2 left"
                                >{{ $t("pages.main") }}
                                <span class="ic-icarrow-left"></span
                            ></router-link>
                            <router-link
                                v-else-if="$route.name == 'productscategory'"
                                :to="{
                                    name: 'products',
                                    params: {
                                        locale:
                                            $i18n.locale == 'uk'
                                                ? null
                                                : $i18n.locale
                                    }
                                }"
                                class="button2 left"
                                >{{ $t("pages.products") }}
                                <span class="ic-icarrow-left"></span
                            ></router-link>
                        </div>
                        <div class="pagiWrp">
                            <pagination
                                :compdata="pagiData"
                                @click="onPagiClick"
                            ></pagination>
                        </div>
                    </div>
                </div>
            </section>
        </transition>

        <modal-base v-if="$store.state.doctorAlert">
            <caution-modal
                @close="onModalClose"
                @accept="onModalAccept"
            ></caution-modal>
        </modal-base>
    </main>
</template>

<script>
import CautionModal from "../components/Modals/CautionModal.vue";
import BreadCrumbs from "../components/Parts/BreadCrumbs.vue";
import CatalogPageFilter from "../components/Parts/CatalogPageFilter.vue";
import ModalBase from "../components/Parts/ModalBase.vue";
import Pagination from "../components/Parts/Pagination.vue";
import ProductGridCard from "../components/Parts/ProductGridCard.vue";
import ProductsGrid from "../components/Parts/ProductsGrid.vue";
export default {
    components: {
        BreadCrumbs,
        ProductsGrid,
        ProductGridCard,
        ModalBase,
        CautionModal,
        CatalogPageFilter,
        Pagination
    },
    name: "ProductsShowcase",
    data() {
        return {
            loaded: false,
            products: null,
            categories: null,
            category: null,

            currentPage: 1,
            totalPage: null
        };
    },
     watch: {
    currentPage( newVal ) {
      document.title = `${this.$store.state.news_title} - ${this.$t('page')} ${newVal}`;
      document.description = `${this.$store.state.news_description} - ${this.$t('page')} ${newVal}`;
    }
  },
    methods: {
        onModalAccept() {
            this.$store.dispatch("hideDoctor");
        },
        onModalClose() {
            this.$router.push({
                name: "MainPage",
                params: {
                    locale: this.$i18n.locale == "ua" ? null : this.$i18n.locale
                }
            });
        },
        onPagiClick(p) {
            switch (p) {
                case "prev":
                    this.loadProducts(this.currentPage - 1);
                    break;
                case "next":
                    this.loadProducts(this.currentPage + 1);
                    break;
                default:
                    this.loadProducts(p);
                    break;
            }
            window.scrollTo({
                top: 200,
                behavior: "smooth"
            });
        },
        findCatbySlug(slug) {
            return this.categories.find(item => item.slug == slug);
        },
        loadProducts(page) {
            let api = "/api/products/all";
            let payload = {
                lang: this.$i18n.locale,
                page: page || 0
            };
            if (this.$route.name == "productscategory") {
                api = "/api/products/get-by-category-slug";
                payload = {
                    lang: this.$i18n.locale,
                    page: page || 0,
                    slug: this.$route.params.category
                };
            }
            let pushObj = { ...this.$route };
            pushObj.query.page = page;
            const resolved = this.$router.resolve(pushObj);
            window.history.pushState(null, null, resolved.href);

            this.axios
                .post(api, payload)
                .then(response => {
                    // document.title = this.$store.state.news_title;
                    this.products = response.data.data.models;
                    this.categories = response.data.data.categories;
                    this.currentPage = response.data.data.paginate.current_page;
                    this.totalPage = Math.ceil(
                        response.data.data.paginate.total /
                            response.data.data.paginate.per_page
                    );
                    this.loaded = true;
                })
                .catch(({ response }) => {
                    this.solveStatus(response.status);
                });
        }
    },
    computed: {
        pagiData() {
            if (this.currentPage) {
                let p = [];
                if (this.currentPage > 1) p.push({ type: "prev", s: true });
                else p.push({ type: "prev", s: false });
                let pre = this.currentPage;
                if (pre > 4) {
                    p.push({ type: "page", l: 1 });
                    p.push({ type: "dot" });
                    for (
                        let i = this.currentPage - 2;
                        i < this.currentPage;
                        i++
                    ) {
                        p.push({ type: "page", l: i, current: false });
                    }
                } else {
                    for (let i = 1; i < this.currentPage; i++) {
                        p.push({ type: "page", l: i, current: false });
                    }
                }
                let post = this.totalPage - this.currentPage;
                if (post > 4) {
                    for (
                        let i = this.currentPage;
                        i < this.currentPage + 3;
                        i++
                    ) {
                        if (i == this.currentPage)
                            p.push({ type: "page", l: i, current: true });
                        else p.push({ type: "page", l: i, current: false });
                    }
                    p.push({ type: "dot" });
                    p.push({ type: "page", l: this.totalPage });
                } else {
                    for (let i = this.currentPage; i <= this.totalPage; i++) {
                        if (i == this.currentPage)
                            p.push({ type: "page", l: i, current: true });
                        else p.push({ type: "page", l: i, current: false });
                    }
                }
                if (this.currentPage < this.totalPage)
                    p.push({ type: "next", s: true });
                else p.push({ type: "next", s: false });
                return p;
            }
            return null;
        },
        brc() {
            if (this.loaded) {
                let a = [{ text: this.$t("pages.main"), url: "/" }];
                if (this.$route.name == "products") {
                    a.push({ text: this.$t("pages.products"), url: "" });
                } else {
                    a.push({
                        text: this.$t("pages.products"),
                        url: "/products"
                    });
                    a.push({
                        text: this.findCatbySlug(this.$route.params.category)
                            .title,
                        url: ""
                    });
                }
                return a;
            }
            return null;
        }
    },
    created() {
        let api = "/api/products/all";
        let payload = {
            lang: this.$i18n.locale,
            page: !isNaN(this.$route.query.page) ? this.$route.query.page : 1
        };
        if (this.$route.name == "productscategory") {
            api = "/api/products/get-by-category-slug";
            payload = {
                lang: this.$i18n.locale,
                page: !isNaN(this.$route.query.page)
                    ? this.$route.query.page
                    : 1,
                slug: this.$route.params.category
            };
        }
        this.axios
            .post(api, payload)
            .then(response => {
                document.title = this.$store.state.products_title;
                document.description = this.$store.getters.products_description;
                this.products = response.data.data.models;
                this.categories = response.data.data.categories;
                this.category = response.data.data.category;
                this.currentPage = response.data.data.paginate.current_page;
                // this.currentPage = 1;
                this.totalPage = Math.ceil(
                    response.data.data.paginate.total /
                        response.data.data.paginate.per_page
                );
                this.loaded = true;
            })
            .catch(({ response }) => {
                this.solveStatus(response.status);
            });
    }
};
</script>

<style lang="scss">
.productsSection {
    padding: 30px 0;
    @media (max-width: $ts) {
        padding: 10px 0 30px;
    }
    .container {
        @include container;
        display: grid;
        grid-template-columns: 25% calc(75% - 1px);
        @media (max-width: $tl) {
            grid-template-columns: 1fr;
        }
        .breadWrp {
            @media (max-width: $tl) {
                order: 1;
                border-bottom: unset !important;
            }
            @media (max-width: $ts) {
                padding: 10px 0;
            }
            .breadCrumbs {
                @media (max-width: $tl) {
                    display: none;
                }
            }
            .back {
                display: none;
                color: $cred1;
                position: relative;
                text-decoration: none;
                padding-bottom: 3px;
                .decoLine1 {
                    background-color: $cred1;
                }
                @media (max-width: $ts) {
                    display: inline-block;
                }
            }
        }
        .breadWrp,
        .sideSectionWrp {
            margin-right: 50px;
        }
        .sideSectionWrp {
            @media (max-width: $tl) {
                order: 1;
                margin: 12px 0 22px;
            }
        }
        .breadWrp,
        .tileWrp {
            border-bottom: 1px solid $cgray6;
        }
        .tileWrp {
            @media (max-width: $tl) {
                order: 2;
            }
            h1 {
                @include h2mob;
                margin: 0 0 30px;
            }
        }
        .prodGridWrp {
            @media (max-width: $tl) {
                order: 3;
            }
        }

        .lnkWrp {
            padding: 15px 0;
            .button2 {
                background-color: #fff;
                border: 1px solid $cgray6;
                &:hover {
                    background-color: $cgraybg1;
                }
            }
        }
        .pagiWrp {
            display: flex;
            justify-content: flex-end;
            padding-top: 30px;
        }
    }
}
</style>
